<template>
  <div class="reserve">
    <!--<h3 class="title">Seleziona la dimensione del cassetto</h3>-->

    <ul class="action-boxes">
      <li>
        <a
          href="#"
          @click="handleSize('s', $event)"
          :class="availableSizes.includes('s') ? 'available' : 'udfnd'"
        >
          <div class="img">Small</div>
          <div class="title">25x25x40cm</div>
        </a>
      </li>
      <li>
        <a
          href="#"
          @click="handleSize('m', $event)"
          :class="availableSizes.includes('m') ? 'available' : 'udfnd'"
        >
          <div class="img">Medium</div>
          <div class="title">35x35x55cm</div>
        </a>
      </li>
      <li>
        <a
          href="#"
          @click="handleSize('l', $event)"
          :class="availableSizes.includes('l') ? 'available' : 'udfnd'"
        >
          <div class="img">Large</div>
          <div class="title">55x55x55cm</div>
        </a>
      </li>
      <li>
        <a
          href="#"
          @click="handleSize('xl', $event)"
          :class="availableSizes.includes('xl') ? 'available' : 'udfnd'"
        >
          <div class="img">Extra</div>
          <div class="title">70x40x55cm</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;
}

.action-boxes {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  margin: 20px;
  width: 100%;

  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      color: #fff;
      background-color: #ddd;
      margin: 10px 50px;
      width: 250px;

      &.available {
        background-color: rgba(green, 0.6);
      }

      &.udfnd {
        background-color: rgba(red, 0.6);
      }

      .img {
        display: block;
        font-size: 60px;
        padding: 10px;
        margin-top: 20px;
      }

      .title {
        display: block;
        background-color: #00838f;
        padding: 10px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
</style>

<script>
import apiService from "@/api/service.js";

export default {
  name: "Leave/SelectSize",
  data() {
    return {
      availableSizes: [],
    };
  },
  async mounted() {
    let freeDrawers = {};
    try {
      freeDrawers = await apiService.freeDrawers();
    } catch (error) {
      console.log("System error while getting free drawers.");
    }

    this.availableSizes = [
      freeDrawers.sCount > 0 ? "s" : null,
      freeDrawers.mCount > 0 ? "m" : null,
      freeDrawers.lCount > 0 ? "l" : null,
      freeDrawers.xlCount > 0 ? "xl" : null,
    ];
  },
  methods: {
    handleSize: function (sizeCode, event) {
      event.preventDefault();

      if (!this.availableSizes.includes(sizeCode)) {
        return;
      }

      const barcodePrefix = this.$route.query.barcode.substr(0, 3);
      const isUserLeave = this.$route.query.action == "userLeave";

      if (barcodePrefix == "001" && !isUserLeave) {
        this.$router.push({
          name: "Leave/ReceiptEmail",
          query: {
            ...this.$route.query,
            sizeCode: sizeCode,
          },
        });
        return;
      }

      this.$router.push({
        name: "Leave/Submit",
        query: {
          ...this.$route.query,
          sizeCode: sizeCode,
          receiptEmail: false,
        },
      });
      return;
    },
  },
};
</script>
